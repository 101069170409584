/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState} from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

const RedirectionTimer = (props) => {
    const [counter, setCounter] = useState(40);
    const [pause, setPause] = useState(false);

    useEffect(() => {
        if (counter > 0 && !pause) {
            setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            window.location.href = 'https://www.willdom.com';
        }
    }, [counter, pause]);
  
    return (
        <div style={{ display: `flex`, flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 15, marginBottom: props.mobile ? 40 : 0 }}>
            <div style={{ display: `flex`, flex: 0.8, alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', fontSize: 14  }}>
                {!pause  && (<div><FormattedMessage id="redirectionTo" /></div> )}
                <a href="https://www.willdom.com">www.willdom.com</a>
                {!pause  && (<div><FormattedMessage id="redirectionIn" />{counter}<FormattedMessage id="redirectionSeconds" /></div>)}
            </div>
            <div style={{ display: `flex`, flex: 0.2, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginLeft: props.mobile ?  0 : 30,
                paddingTop: props.mobile ? 10 : 0}}>
                <div
                    onClick={() => setPause(!pause)}
                    style={{
                        margin: props.mobile ? 0 : 10,
                        minWidth: props.mobile ? 100 : 250,
                        backgroundColor: pause ? `#ffde00` : `white`,
                        display: 'inline-block',
                        textDecoration: `none`,
                        cursor: `pointer`,
                        border: 2,
                        borderRadius: 5,
                        borderStyle: 'solid',
                        textAlign: 'center',
                        borderColor: pause ? `#ffde00` : `black`,
                        padding: '10px 20px'
                    }}
                > {pause ? <FormattedMessage id="redirectResume" /> : <FormattedMessage id="redirectPause" />} </div>
            </div>
        </div>
    )
}

export default RedirectionTimer
