import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Information from "../components/information"
import { Media } from "../media"

import Logos from "../images/logos.svg";
import Borde from "../images/borde.svg";
import SEO from "../components/seo"

const IndexPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
          lang={intl.locale}
          title={intl.formatMessage({ id: "information" })}
          keywords={[`gatsby`, `application`, `react`]} />
      <Media lessThan="md">
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <div style={{ display: `flex`, flex: 1, alignItems: 'center',  width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: `flex`, alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
              <Information/>
            </div>
            <div style={{ display: `flex`, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <img src={Logos} alt="logos" width="300"/>
            </div>
          </div>
        </div>

      </Media>
      <Media greaterThanOrEqual="md">
        <div style={{ display: `flex`, flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
          <div style={{ display: `flex`, flex: 0.1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <img src={Borde} alt="borde" width="50"/>
          </div>
          <div style={{ display: `flex`, flex: 0.6, alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
            <Information/>
          </div>
          <div style={{ display: `flex`,  flex: 0.3,  alignItems: 'center', justifyContent: 'center', flexDirection: 'row', padding: 30 }}>
            <img src={Logos} alt="logos" width="300"/>
          </div>
        </div>
      </Media>

    </Layout>
  )
}

export default IndexPage
