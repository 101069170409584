/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import RedirectionTimer from "./RedirectionTimer"
import { Media } from "../media"

const Information = () => {
  
    return (
        <>
            <h1>
            <FormattedMessage id="paragraphTitle" />
            </h1>
            <p>
            <FormattedMessage id="paragraph1" />
            </p>
            <p>
            <FormattedMessage id="paragraph2" />
            </p>
            <p>
            <FormattedMessage id="paragraph3" />
            </p>
            <p>
            <FormattedMessage id="paragraph4" />
            </p>
            <p>
            <FormattedMessage id="paragraph5" />
            </p>
            <footer>
                <Media lessThan="md">
                    <RedirectionTimer mobile style={{ paddingTop: 40 }} />
                </Media>

                <Media greaterThanOrEqual="md">
                    <RedirectionTimer />
                </Media>
            </footer>
        </>
    )
}

export default Information
